<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits,
} from "radix-vue";
import { X } from "lucide-vue-next";
import { cn } from "@/lib/utils";

const props = defineProps<
  DialogContentProps & {
    class?: HTMLAttributes["class"];
    size?: "md" | "lg" | "sm";
    disableCloseOnOutsideClick?: Boolean;
  }
>();
const emits = defineEmits<DialogContentEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <DialogPortal>
    <DialogOverlay
      class="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 dialog-overlay"
    >
      <DialogContent
        v-bind="forwarded"
        :class="
          cn(
            'relative dialog-content w-[calc(100%-20px)] my-8 outline-none z-50 grid gap-4  bg-background p-[60px] sm:p-16 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 rounded-3xl',
            size === 'lg' && 'max-w-4xl',
            size === 'md2' && 'max-w-[600px]',
            size === 'md' && 'max-w-[540px]',
            (size === 'sm' || !size) && 'max-w-xl',
            props.class,
          )
        "
        @pointer-down-outside="
          (event) => {
            if (disableCloseOnOutsideClick) {
              event.preventDefault();
            }
          }
        "
      >
        <DialogClose
          class="absolute z-10 right-4 top-4 sm:right-14 sm:top-14 text-mono-light-grey hover:text-mono-black rounded-full w-12 h-12 flex items-center justify-center ring-offset-background transition-all duration-150 hover:opacity-100 hover:bg-mono-off-white hover:rotate-180 focus-visible:rotate-180 font-bold focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <X class="w-6 h-6 stroke-[2px] stroke-current" />
          <span class="sr-only">Close</span>
        </DialogClose>
        <slot />
      </DialogContent>
    </DialogOverlay>
  </DialogPortal>
</template>

<style scoped>
.dialog-content {
  /* transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275); */
}

.dialog-overlay {
  display: grid;
  place-items: center;
  overflow-y: auto;
  position: fixed;
}
</style>
